export default class Modal extends Component {
  static propTypes = {
    title: PropTypes.string,
    opened: PropTypes.bool,
    children: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.element = React.createRef()
  }

  componentDidMount()  { this.show() }
  componentDidUpdate() { this.show() }

  componentWillUnmount() {
    $('body > .modal-backdrop').remove() // need when there is a modal on top of another modal
    $('body').removeClass('modal-open')
  }

  render() {
    let { title, opened, children, size = 'lg' } = this.props
    if (!opened) return null
    Modal.current = this

    let content = (
      <div className="modal" tabIndex="-1" ref={this.element}>
        <div className={classNames("modal-dialog modal-dialog-centered", {'modal-lg': size == 'lg', 'modal-sm': size == 'sm'})}>
          <div className="modal-content">
            { children }
          </div>
        </div>
      </div>
    )
    return ReactDOM.createPortal(content, document.getElementById('modal'))
  }

  onHide = () => {
    if (this.closeCallbackDisabled) return
    if (typeof this.props.onClose == 'function') this.props.onClose()
    if (appState.objects.returnPath) {
      App.goDirect(appState.objects.returnPath)
      appState.deleteObjectSilent('returnPath')
      return
    }
    if (typeof this.props.onClose == 'string') {
      App.goDirect(this.props.onClose)
    }
  }

  show() {
    if (this.props.opened) {
      $(this.element.current).
        modal('show').
        off('hidden.bs.modal', this.onHide).
        on('hidden.bs.modal', this.onHide)
    }
  }

  hide() {
    $(this.element.current).modal('hide')
  }
}

Modal.CloseButton = (props = {}) =>
  <button type="button" className="close close-modal-btn" onClick={() => { Modal.current.hide() }}>
    <span>&times;</span>
  </button>

// document.body.classList.add('modal-open')
// modal-visible show
