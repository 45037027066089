import { ApiModel }       from 'base'
import SmartButtonApi from './smart-button-api'
import ImageGallery   from './image-gallery'

export default class SmartButton extends ApiModel {
  constructor(attributes) {
    super(attributes)
    if (this.kind == 'gallery') {
      this.gallery = new ImageGallery({ id: this.id, images: this.images, smartButton: this })
      appState.updateModels([this.gallery], { trigger: false })
    }
  }

  get key() { return `${this.type} ${this.title} ${this.action} ${this.event_id} ${this.occurrence_id} ${this.place_id} ${this.listing_flags} ${this.guest_experience_id}` }

  // get isDirections()          { return this.action === "event:directions" }
  // get isCalendar()            { return this.action === "event:calendar" }
  get isEventReserve()        { return this.action === "event:reserve" }
  get isEventReserveCancel()  { return this.action === "event:reserve:cancel" }
  get isEventInterest()       { return this.action === "event:interested" }
  get isEventInterestCancel() { return this.action === "event:interested:cancel" }
  get isEventEnroll()         { return this.action === "event:enroll" }
  get isEventEnrollCancel()   { return this.action === "event:enroll:cancel" }
  get isParkReserve()         { return this.action === "place:reserve" }
  get isParkReserveCancel()   { return this.action === "place:reserve:cancel" }
  get isFileDownload()        { return this.type === "smart-button:file-download" }
  get isEventReservation()    { return this.type === "smart-button:event-reservation" }
  get isParkReservation()     { return this.type === "smart-button:place-reservation" }
  get isEventListing()        { return this.type === "smart-button:event-listing" }
  get isAdminRequest()        { return this.type === "smart-button:admin-request" }
  get isMenu()                { return this.type === "smart-button:menu" }

  get isSubscriptionBased() { return this.isEventInterest || this.isEventInterestCancel || this.isEventEnroll || this.isEventEnrollCancel }
  get isForPark() { return this.isParkReserve || this.isParkReserveCancel }
  get isCurrentEventReservation() { return this.isEventReserve || this.isEventReserveCancel }

  get shouldOpenNewChat() { return this.hint && this.hint.ui_action == 'new-chat' }

  get eventAction() { return eventActionMapping[this.action] }
  get userRequestId() { return this.user_request && this.user_request.id }
  get parentRequest() { return this.parentUserRequestId && App.state.entities[`user-request:${this.parentUserRequestId}`] }
  get hasOpenUserRequest() { return this.user_request && this.user_request.status === 'pending' }

  get actionOrType() { return this.action || this.type }
  get kind() {
    return this.type == 'smart-button:server-action' ?
      'server-action' :
       this.action || this.type.replace('smart-button:', '')
  }

  get jsObjectId() {
    switch (this.kind) {
      case "place":
      case "place:reserve":
      case "place:reserve:cancel":
      case "place-reservation":
        return `park:${this.place_id}`
      case "retail":
        return `retail:${this._id}`
      case "club":
        return `club:${this.club_id}`
      case "event":
      case "event:reserve:cancel":
        return this.occurrence_id ? `event:occurrence:${this.occurrence_id}` : `event:${this.event_id}`
      case "event:reserve":
      case "event-reservation":
        return `event:${this.event_id}::reserve${this.occurrence_id ? ':' + this.occurrence_id : ''}`
      case "gallery":
        return `gallery:${this.id}`
      default:
        console.error("Can not determine js object id", this)
    }
  }

  get listingRoute() {
    return [_.first(this.listing_flags), this.listing_period, this.id].join('__')
  }

  get isSupported() {
    return supportedButtonTypes.includes(this.kind)
  }

  get embeddedInMenu() {
    return this.parentRequest && this.parentRequest.userButton.kind == 'menu-set'
  }

  get cleanedPhone() {
    return (this.phone || '').replace(/[\s-()]/g, '')
  }

  buttonWithParams(otherParams) {
    this.params = this.params ? { ...this.params, ...otherParams } : otherParams
    return this
  }

  get opensNewPage() {
    return ['event-listing', 'place-listing', 'available-place-listing', 'place-checkin-users'].includes(this.kind)
  }
}

const eventActionMapping = {
  "event:interested":        "subscribe",
  "event:interested:cancel": "cancelSubscription",
  "event:enroll":            "subscribe",
  "event:enroll:cancel":     "cancelSubscription",
  "event:reserve":           "reserve",
  "event:reserve:cancel":    "cancelReservation",
}

const supportedButtonTypes = [
  "event",
  "event:reserve",
  "event:reserve:cancel",
  "event:interested",
  "event:interested:cancel",
  "event:enroll",
  "event:enroll:cancel",
  "event:directions",
  "event:calendar",
  "event:attendee",
  "place",
  "place:reserve",
  "place:reserve:cancel",
  "club",
  "club:enroll",
  "club:enroll:cancel",
  "club:member",
  "retail",
  "retail:enroll",
  "retail:enroll:cancel",
  "file-download",
  "event-reservation",
  "place-reservation",
  "event-listing",
  "admin-request",
  "menu",
  "menu-set",
  "url",
  "password-manager-url",
  "email",
  "phone",
  "text-message",
  "payment",
  "user-text",
  "location:share",
  "image:upload",
  "chat:undo",
  "user-request:cancel",
  "server-action",
  "gallery",
  "guest-pass:signup",
  "guest-pass:save-event",
  "guest-pass:save-place",
  "guest-experience",
  "video-chat",
  "user-listing",
  "place-listing",
  "available-place-listing",
  "clubs-groups",
  "qr-code",
  "place-checkin-users",
  "clubs-groups"
]


SmartButton.feedSource = {
  requestSmartButtons(success) {
    return SmartButtonApi.forFeed(success)
  }
}
